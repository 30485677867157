import {DataSet} from '../../../shared/models/DataSet';
import {Injectable} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {finalize} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Slider} from './slider';
import {BaseApiService} from "../../../shared/services/base-api.service";

@Injectable({
    providedIn: 'root'
})
export class SliderService extends BaseApiService<Slider> {

    override endPoint = environment.restApiHost + 'v1/slider/'

    public listAll(activeOnly = true): Observable<DataSet<Slider>> {
        const queryParams = new HttpParams().append('page', '0')
            .append('search', JSON.stringify(activeOnly ? {active: 1} : {}))
            .append('limit', '-1')
            .append('orderBy', 'position')
            .append('ts', new Date().getTime().toString()); // prevent caching in IE
        return this.list(queryParams);
    }

    public saveSliderPositioning(sliderPositioning: Array<{ id: number, position: number, active: number }>) {
        return this.http.post(this.endPoint + 'position', sliderPositioning).pipe(
            finalize(() => {
                this.dataChangedEmit()
            })
        )
    }
}
